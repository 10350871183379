<template>
<div>
  <!-- documents of doctor {{this.$route.params.id}} -->
  <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">المستندات</dashboard-page-title>
  <b-row v-if="doctorData">
    <b-col md="6" lg="3" v-for="item in doctorData.uploaded_documents" :key="item.id">
      <div class="img-upload-box">
        <h4 class="img-upload-box-label">{{item.doc.title}}</h4>
        <!-- box  -->
        <div class="img-box">
          <img :src="item.url" alt="img" class="img-fluid" />
        </div>
      </div>
    </b-col>
    <div class="d-flex justify-content-center mt-5 col-12">
      <b-button variant="primary" class="vete-save-btn m-auto" type="button" @click="acceptDocumnets" v-if="!loadingButtonSubmit"> قبول </b-button>
      <b-button variant="primary" class="vete-save-btn m-auto" v-else> <spinner-loading :text="'يتم التحميل'"></spinner-loading> </b-button>
    </div>
  </b-row>
</div>
</template>

<script>
import { core } from '@/config/pluginInit'
import api from '../services'
export default {
  data () {
    return {
      doctorData: null,
      loadingButtonSubmit: false
    }
  },
  methods: {
    getDcotorData () {
      api.getDoctorData(this.$route.params.id).then(res => {
        this.doctorData = res.data
      })
    },
    acceptDocumnets () {
      this.loadingButtonSubmit = true
      api.acceptDoctorDocumnets(this.$route.params.id).then(res => {
        console.log(res)
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'documents' })
      }).finally(() => {
        this.loadingButtonSubmit = false
      })
    }
  },
  created () {
    this.getDcotorData()
  }
}
</script>
