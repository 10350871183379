import { veterinary, website } from '@/axios'

export default {
  acceptDoctor (id) {
    return veterinary().patch(`admin/doctors/${id}`, {
      status: 'accepted'
    })
  },
  statusDoctor (id, status, payload) {
    return veterinary().patch(`admin/doctors/${id}`, {
      status: status,
      ...payload
    })
  },
  acceptClinic (id) {
    return veterinary().patch(`admin/clinics/${id}`, {
      status: 'accepted'
    })
  },
  statusClinic (id, status, payload) {
    const data = payload ? { ...payload, status } : { status }
    return veterinary().patch(`admin/clinics/${id}`, data)
  },
  getClinic (id) {
    return veterinary().get(`admin/clinics/${id}`)
  },
  editClinic (id, payload) {
    return veterinary().patch(`admin/clinics/${id}`, payload)
  },
  getDoctor (id) {
    return veterinary().get(`admin/doctors/${id}`)
  },
  editDoctor (id, payload) {
    return veterinary().patch(`admin/doctors/${id}`, payload)
  },
  getSettings () {
    return veterinary().get('admin/settings')
  },
  postSettings (data) {
    return veterinary().post('admin/settings', data)
  },
  getDoctorData (id) {
    return veterinary().get(`admin/doctors/${id}`)
  },
  acceptDoctorDocumnets (id) {
    return veterinary().patch(`admin/doctors/${id}`, { status: 'accepted' })
  },
  getDocuments () {
    return veterinary().get('acceptance-docs')
  },
  getDocument (id) {
    return veterinary().get(`acceptance-docs/${id}`)
  },
  editDocument (id, payload) {
    return veterinary().patch(`acceptance-docs/${id}`, payload)
  },
  postDocument (payload) {
    return veterinary().post('acceptance-docs', payload)
  },
  getRequiredDocuments () {
    return veterinary().get('acceptance-docs?required_for=doctors')
  },
  uploadDotorDocument (payload) {
    return veterinary().post('upload', payload)
  },
  addDoctor (payload) {
    return veterinary().post('clinics/add-doctor', payload)
  },
  getWithdrawalRequest (id) {
    return website().get(`admin/wallets/withdrawal-requests/${id}`)
  },
  statusWithDrawal (id, status, payload) {
    return website().patch(`admin/wallets/withdrawal-requests/${id}/`, {
      status: status,
      ...payload
    })
  }
}
